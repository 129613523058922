<template>
    <div>
		<trend :data="primeUserReportData"
                :gradient="['#007bff']"
                :stroke-width="1.5"
                :height="40"
                :width="200"
                auto-draw>
        </trend>
    </div>
</template>
<script>
import userApi from '@/api/common/users';
import Trend from "vuetrend";
export default {    
    components: {
		Trend
	},
	props: {
		userId: {
			type: String,
			required: true
		}
	},
    data() {
		return { 
			primeUserReportData: []
        };
    },

    mounted() {
		this.getPrimeUserReportData();
    },
    methods: {
		getPrimeUserReportData() {
			userApi.getPrimeUserReportData(this.userId)
				.then((response) => {
					if (response.data.result === 0) {
						this.primeUserReportData = response.data.data;
						this.$emit('onReportDataLoaded', this.primeUserReportData);
					}
					else {
						console.error(response.data);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
    }
}
</script>